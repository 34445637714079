import { useCommonApi } from '~/api/common'
import { IContact } from '~/types/footer'
import { useAppStore } from '~/store/app'
export const useContact = () => {
  const { $axios, $pinia } = useNuxtApp()
  const { BRANCH_CONTACT } = useCommonApi()
  const contacts = ref<IContact[]>([])
  const store = useAppStore($pinia)
  const fetchBranchContact = async () => {
    try {
      if (store.contacts.length) {
        contacts.value = store.contacts
        return
      }
      const { data: response } = await $axios.get(BRANCH_CONTACT)
      if (response && response.status === 'OK') {
        contacts.value = response.data.slice(0, 3)
        store.setContacts(contacts.value)
      }
    } catch (error) {
      console.log(error)
    }
    return []
  }

  return {
    fetchBranchContact,
    contacts
  }
}
